<template>
  <div class="login">
    <el-row type="flex" class="header">
      <el-col :span="5"></el-col>
      <el-col :span="6">
        <img class="logo_img" src="@/assets/image/logo_1.png" alt="" />
      </el-col>
      <!--<el-col :span="4"></el-col>-->
      <el-col :span="16" style="line-height: 40px;margin-top: 2.5rem;">
        <span style="color: #eb2f3d;font-size: 1.5rem;font-weight: bold;">每日一点，助力排名！动动小手，点击进入下方链接哦</span><br/>
        <a href="https://store.iis.com.cn/" target="_blank" style="color: #eb2f3d;font-size: 1.1rem;font-weight: bold;">跳转链接(可点击跳转)</a>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
    <el-row type="flex" class="centrer" :style="backgroundDiv">
      <el-col :span="4"></el-col>
      <el-col :span="16" style="position: relative;">
        <div class="login_infor">
          <h3>欢迎登录禹佳集采商城</h3>
          <el-form ref="dataForm" :model="dataForm" :rules="dataRule" @submit.native.prevent>
            <el-form-item prop="userName">
              <el-input
                v-model="dataForm.userName"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="verifyCode">
              <el-input
                v-model="dataForm.verifyCode"
                placeholder="请输入验证码"
                style="width:182px;"
              ></el-input>
              <el-button
                :disabled="disabled"
                class="login-btn-authCode"
                @click="authCode()"
                >{{ valiBtn }}</el-button
              >
            </el-form-item>
            <AuthCode ref="authcode" />
            <el-form-item>
              <el-button
                class="login-btn-submit"
                @click="sonfun()"
                native-type="submit"
                >登录</el-button
              >
            </el-form-item>

            <el-form-item style="display:none;">
              <div class="flex-between">
                <div class="flex-start">
                  <el-checkbox v-model="checkeds">记住密码</el-checkbox>
                </div>
                <div>
                  <span @click="findPwd()">忘记密码</span>
                  <span style="margin:0 7px;">|</span>
                  <span>忘记用户名</span>
                </div>
              </div>
            </el-form-item>
            <div
              class="login_apply"
              @click="dialogFormVisible = true"
              style="display:none;"
            >
              申请注册
            </div>
          </el-form>
        </div>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>

    <!-- 注册弹框 -->
    <el-dialog
      title="申请注册"
      :visible.sync="dialogFormVisible"
      width="25%"
      center
    >
      <el-form ref="dataForm" :model="dialogForm">
        <el-form-item prop="userName">
          <el-input
            v-model="dialogForm.userName"
            placeholder="请填写您的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="company">
          <el-input
            v-model="dialogForm.company"
            placeholder="请提供真实企业公司的名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="number">
          <el-input
            v-model="dialogForm.number"
            placeholder="请填写您的手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="mailbox">
          <el-input
            v-model="dialogForm.mailbox"
            placeholder="请填写您的邮箱号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="demand">
          <el-input
            type="textarea"
            v-model="dialogForm.demand"
            placeholder="请填写您的采购需求"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item prop="demand">
          <el-input
            v-model="dialogForm.demand"
            placeholder="请输入图形验证码"
            style="width:181px;"
          ></el-input>
          <img src="@/assets/image/u218.png" alt="" />
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="checked"
            >我已阅读并同意《禹洲商城用户注册条款》</el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="warning"
            class="register-btn"
            :disabled="button == false"
            >注册</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="footer_bom">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Layout/footer.vue";
import AuthCode from "@/components/Layout/authCode.vue";
import { getCode, Login } from "@/api/api";
import { phoneReg } from '@/utils/regular'
export default {
  components: { Footer, AuthCode },
  data() {
    return {
      dialogFormVisible: false,
      checked: false,
      checkeds: false,
      button: false,
      visible: true,

      backgroundDiv: {
        backgroundImage: "url(" + require("@/assets/image/login_bck.png") + ")"
      },
      dataForm: {
        userName: "",
        verifyCode: ""
      },
      valiBtn: "获取验证码",
      disabled: false,
      dialogForm: {
        userName: "",
        company: "",
        number: "",
        mailbox: "",
        demand: ""
      },
      dataRule: {
        userName: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { pattern: phoneReg, message: "请输入正确的手机号码" }
        ],
        verifyCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    // console.log(process.env.NODE_ENV);
    // let that = this;
    // document.onkeypress = function(e) {
    //   var keycode = document.all ? event.keyCode : e.which;
    //   if (keycode == 13) {
    //     that.sonfun();
    //     return false;
    //   }
    // };
  },
  methods: {
    findPwd() {
      this.$router.push({ name: "FindPassword" });
    },
    tackBtn() {
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.valiBtn = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    authCode() {
      if(this.disabled) return;
      this.disabled = true
      localStorage.setItem("token", "");
      this.$refs["dataForm"].validateField("userName", err => {
        if (!err) {
          let params = {
            mobile: this.dataForm.userName
          };
          // this.tackBtn();
          getCode(params).then(res => {
            if (res && res.code === "00000") {
              this.tackBtn();
            } else if (res.code === "E0001") {
              // this.$alert(res.desc, {
              //   confirmButtonText: '确定',
              // });
              // this.$toast(res.desc);
              this.$message({
                type: "error",
                duration: 8000,
                title: "错误",
                message: res.desc
              });
              this.disabled = false
            }
          }).catch(() => {
            this.disabled = false
          });
        } else { this.disabled = false }
      });
    },
    sonfun() {
      // localStorage.setItem('token','')
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          const data = {
            mobile: this.dataForm.userName,
            code: this.dataForm.verifyCode
          };
          Login(data).then(res => {
            if(res.code && res.code != '00000'){
                this.$message({
                    type: "error",
                    duration: 8000,
                    message: res.desc
                });
            } else if (res) {
                localStorage.setItem("token", res.access_token);
                this.$store.commit("setAcc", this.dataForm.userName);
                this.$store.commit("setId", { userId: res.userId, externalId: res.externalId });
                this.$router.replace({ name: "Index" });
                // this.notify = this.$notify({
                //     title: '温馨提示',
                //     duration: 0,
                //     dangerouslyUseHTMLString: true,
                //     message: `<div style="font-size: 15px;color: #000;">
                //         受降雪及路面结冰影响，北京、天津、河北、山西、黑龙江、山东、河南、陕西等多地区路段封闭，快递时效不保，不接急单，急件请提前计划哦！
                //     </div>`
                // })
            }
          });
        }
      });
      // this.$refs.authcode.sonFun(this.visible);
    }
  }
};
</script>

<style lang="scss">
.login {
  width: 100%;
}
.header {
  height: 169px;
  line-height: 210px;
  .logo_img {
    height: 60px;
  }
}

.centrer {
  width: 100%;
  height: 586px;
  background-size: 100% 100%;
  .centre_img {
    width: 100%;
    height: 586px;
  }

  .login_infor {
    width: 305px;
    border: 1px solid black;
    background: #fff;
    position: absolute;
    right: 0;
    top: 66px;
    border-radius: 6px;
    padding: 60px 85px;
    h3 {
      font-size: 22px;
      font-weight: 500;
      padding-bottom: 20px;
    }
    .el-input__inner {
      border: 1px solid #606266;
    }
    .login-btn-authCode {
      width: 108px;
      border: 1px solid #cd9f49;
      background-color: #fff;
      border-radius: 6px;
      margin-left: 15px;
      font-size: 14px;
      color: #cd9f49;
    }
    .login-btn-submit {
      width: 100%;
      background: #cd9f49;
      color: #fff;
      height: 45px;
      letter-spacing: 2px;
    }
    .login_apply {
      text-align: center;
      color: #cd9f49;
      font-size: 18px;
    }
  }
}

//注册弹框

.el-dialog__body .el-form-item__content {
  // display: flex;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333 !important;
}
.register-btn {
  width: 400px;
  height: 45px;
  letter-spacing: 2px;
}
.footer_bom {
  width: 100%;
}
</style>
